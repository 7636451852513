import FeatherIcon from "feather-icons-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import { getBookingApiData, getFavoriteApiData } from "../../slices/thunks";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FormatedDate } from "../../helpers/date_helper";
import { LocationIcon, Star, StarFilled } from "../SwipePages/components/svg/Svg";
import DeleteModal from "../SwipePages/components/DeleteModal";
import { Booking, lockSmartLock, unlockSmartLock } from "../../slices/bookedKeys/thunk";
import { resetError } from "../../slices/dashboardEcommerce/reducer";
import WarningAlert from "../SwipePages/components/WarningAlert";
import Pagination from "../../Components/Common/Pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resetBooking } from "../../slices/bookedKeys/reducer";
import { FaRegCopy } from "react-icons/fa";
import { IoEyeOutline } from "react-icons/io5";
import { AddFavKey, RemoveFavKey } from "../../slices/keys/thunk";
import SmartButtonGroup from "../SwipePages/components/SmartButtonGroup";

const DashboardEcommerce = () => {
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const [rejectedId, setRejectedId] = useState(null);
  const [rejectedStatus, setRejectedStatus] = useState(null);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStars, setSelectedStars] = useState({});
  const [baseUrl, setBaseUrl] = useState("");
  const [isCopied, setIsCopied] = useState({});

  const perPageData = 6;


  const getBookingApiDataRes = useSelector(
    (state) => state.DashboardEcommerce.getBookingData.data
  );
  const getFavoriteApiDataRes = useSelector(
    (state) => state.DashboardEcommerce.getFavoriteData.data
  );
  const loading = useSelector((state) => state.DashboardEcommerce.loading);
  const errorRes = useSelector((state) => state.DashboardEcommerce.error);
  const bookingRes = useSelector((state) => state.BookedSlice.booking);

  useEffect(() => {
    if (getFavoriteApiDataRes) {
      const starsState = {};
      getFavoriteApiDataRes.forEach((item) => {
        starsState[item?._id] = item.isFavorite;
      });
      setSelectedStars(starsState);
    }
  }, [getFavoriteApiDataRes]);

  const handleToggle = (id) => {
    setSelectedStars((prevState) => {
      const isStarSelected = !prevState[id];
      if (isStarSelected) {
        dispatch(AddFavKey(id));
      } else {
        dispatch(RemoveFavKey(id));
      }
      return {
        ...prevState,
        [id]: isStarSelected,
      };
    });
  };

  const handleCopyClick = (link, keyId, type) => {
    const copyKey = `${keyId}_${type}`;
    navigator.clipboard.writeText(link).then(() => {
      setIsCopied({ ...isCopied, [copyKey]: true });
      setTimeout(() => setIsCopied({ ...isCopied, [copyKey]: false }), 500);
    });
  };

  const handleSmartLockButtonClick = async (buttonName, key) => {
    try {
      if (buttonName === "lock") {
        const res = await dispatch(lockSmartLock(key?.smartlockId));
        if (res && res.payload?.success) {
          const newStatus = { ...smartLockStatus };
          newStatus[key?._id] = "locked";
          setSmartLockStatus(newStatus);
        }
      } else if (buttonName === "unlock") {
        const res = await dispatch(unlockSmartLock(key?.smartlockId));
        if (res && res.payload?.success) {
          const newStatus = { ...smartLockStatus };
          newStatus[key?._id] = "open";
          setSmartLockStatus(newStatus);
        }
      }
    } catch (error) {
      console.error("Error locking smart lock:", error);
    }
  };

  const handleOpenerButtonClick = async (buttonName, key) => {
    try {
      if (buttonName === "lock") {
        const res = await dispatch(lockSmartLock(key?.openerId));
        if (res && res.payload?.success) {
          const newStatus = { ...openerStatus };
          newStatus[key?._id] = "locked";
          setOpenerStatus(newStatus);
        }
      } else if (buttonName === "unlock") {
        const res = await dispatch(unlockSmartLock(key?.openerId));
        if (res && res.payload?.success) {
          const newStatus = { ...openerStatus };
          newStatus[key?._id] = "open";
          setOpenerStatus(newStatus);
        }
      }
    } catch (error) {
      console.error("Error locking smart lock:", error);
    }
  };

  const handleNextKeysApi = () => {
    setPage((val) => val + 1);
    let params = {
      page: page + 1,
      limit: perPageData,
    };
    dispatch(getBookingApiData(params));
  };

  const handlePrevKeysApi = () => {
    setPage((val) => val - 1);
    let params = {
      page: page - 1,
      limit: perPageData,
    };
    dispatch(getBookingApiData(params));
  };


  useEffect(() => {
    if (bookingRes && bookingRes?.success) {
      // dispatch(getBookingApiData());
      dispatch(getFavoriteApiData());
    } else {
      // dispatch(getBookingApiData());
      dispatch(getFavoriteApiData());
    }
  }, [bookingRes]);

  // useEffect(() => {
  //   if (bookingRes && bookingRes?.success) {
  //     toast(bookingRes.message, {
  //       position: "top-right",
  //       hideProgressBar: false,
  //       className: "bg-success text-white",
  //       progress: undefined,
  //       toastId: "",
  //     });
  //   }
  // }, [bookingRes]);

  useEffect(() => {
    let params = {
      page: page,
      limit: perPageData,
    };
    dispatch(getBookingApiData(params));
    dispatch(resetBooking())
  }, [bookingRes?.success]);

  const activebtn = (ele) => {
    if (ele.closest("button").classList.contains("active")) {
      ele.closest("button").classList.remove("active");
    } else {
      ele.closest("button").classList.add("active");
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetError());
    };
  }, []);

  const confirmDelete = () => {
    if (rejectedId !== null) {
      let params = {
        bookingId: rejectedId,
        status: rejectedStatus,
      };
      dispatch(Booking(params));
      setDeleteModal(false);
      setRejectedId(null);
      setRejectedStatus(null);
    }
  };


  const handleBooking = (id, status) => {
    let params = {
      bookingId: id,
      status: status,
    };
    if (status === "rejected") {
      setRejectedId(id);
      setRejectedStatus(status);
      setDeleteModal(true);
    } else if (status === "cancelled") {
      dispatch(Booking(params));
    } else if (status === "booked") {
      dispatch(Booking(params));
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {errorRes && errorRes ? <WarningAlert msg={errorRes} /> : null}
        <DeleteModal
          show={deleteModal}
          onDeleteClick={confirmDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
        <ToastContainer autoClose={2000} limit={1} />

        <Card>
          <CardHeader className="">
            {" "}
            <h6 className="card-title mb-0 flex-grow-1 fw-bold">
              Favourite Keys
            </h6>
          </CardHeader>
        </Card>
        <Row>
          {getFavoriteApiDataRes && getFavoriteApiDataRes.length > 0 ? (
            getFavoriteApiDataRes?.map((key) => (
              <Col lg={6}>

                <div
                  key={key?._id}
                  className="card task-box"
                  id={`key-${key?._id}`}
                >
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <div className="mb-3">
                        <h6 className="fs-15 mb-0 flex-grow-1 text-truncate task-title">
                          <div className="d-block" id="task-name">
                            {key?.name}
                          </div>
                        </h6>
                      </div>
                      <div className="d-flex justify-content-around">
                        <div className="me-3 cursor-pointer" onClick={() => handleToggle(key?._id)}>{selectedStars[key?._id] ? <StarFilled /> : <Star />}
                        </div>
                        <UncontrolledDropdown className="float-end cursor-pointer">
                          <DropdownToggle
                            className="arrow-none"
                            tag="a"
                            color="white"
                          >
                            <i className="ri-more-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <Link
                              to={`/view-key/${key?._id}`}
                              className="Link"
                            >
                              <DropdownItem className="edittask-details">
                                View
                              </DropdownItem>
                            </Link>
                            <Link to={`/edit-key/${key?._id}`}>
                              <DropdownItem className="edittask-details">
                                Edit
                              </DropdownItem>
                            </Link>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>

                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <div className="d-flex">
                        <LocationIcon width={20} height={20} />
                      </div>
                      <div className="d-flex gap-2 text-center">
                        <h6 className="text-muted mt-1">Address: </h6>
                        <h6 className="mt-1">{key?.address}</h6>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col">
                        {key?.long_term_booking_enabled && (
                          <div className="row mb-3">
                            <div>
                              <h6 className="text-muted mb-0 col-sm-6">
                                Long Term Key Booking Link:
                              </h6>
                              <div className="d-flex row align-items-center mt-1">
                                <div className="col-sm-4 col-10">
                                  <input
                                    type="text"
                                    value={`${baseUrl}/book-a-key/long-term/${key?._id}`}
                                    readOnly
                                    className="form-control"
                                    style={{
                                      flex: 1,
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                    }}
                                  />
                                </div>
                                <div className="col-sm-1 col-2 d-sm-flex">
                                  <FaRegCopy
                                    style={{ cursor: "pointer" }}
                                    size={20}
                                    onClick={() =>
                                      handleCopyClick(
                                        `${baseUrl}/book-a-key/long-term/${key?._id}`,
                                        key?._id,
                                        "longTerm"
                                      )
                                    }
                                  />
                                  {isCopied[`${key.key?._id}_longTerm`] && (
                                    <div
                                      className="ml-3"
                                      style={{ color: "green" }}
                                    >
                                      Copied!
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {key?.short_term_booking_enabled && (
                          <div className="row mb-3">
                            <div>
                              <h6 className="text-muted mb-0 col-sm-6">
                                Short Term Key Booking Link:
                              </h6>
                              <div className="d-flex row align-items-center mt-1">
                                <div className="col-sm-4 col-10">
                                  <input
                                    type="text"
                                    value={`${baseUrl}/book-a-key/short-term/${key?._id}`}
                                    readOnly
                                    className="form-control"
                                    style={{
                                      flex: 1,
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                    }}
                                  />
                                </div>
                                <div className="col-sm-1 col-2 d-sm-flex">
                                  <FaRegCopy
                                    style={{ cursor: "pointer" }}
                                    size={20}
                                    onClick={() =>
                                      handleCopyClick(
                                        `${baseUrl}/book-a-key/short-term/${key?._id}`,
                                        key?._id,
                                        "shortTerm"
                                      )
                                    }
                                  />
                                  {isCopied[`${key?._id}_shortTerm`] && (
                                    <div
                                      className="ml-3"
                                      style={{ color: "green" }}
                                    >
                                      Copied!
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      {key?.smartLockStatus && (
                        <div className="col-md-6 mt-sm-3">
                          <div className="d-flex gap-3 align-items-center">
                            <h6 className="font-weight-bold text-lg">
                              Smart Lock{" "}
                            </h6>
                            <h6 className="text-lg text-muted">
                              status:{" "}
                              <span className="orange-icon">
                                {key?.smartLockStatus}
                              </span>
                            </h6>
                          </div>
                          <div className="">
                            {key && (
                              <SmartButtonGroup
                                handleSmartLockButtonClick={
                                  handleSmartLockButtonClick
                                }
                                newKey={key}
                                smartLockStatus={smartLockStatus}
                              />
                            )}
                          </div>
                        </div>
                      )}
                      {key?.openerStatus && (
                        <div className="col-md-6 mt-sm-3">
                          <div className="d-flex gap-3 align-items-center">
                            <h6 className="font-weight-bold text-lg">
                              Smart Lock{" "}
                            </h6>
                            <h6 className="text-lg text-muted">
                              status:{" "}
                              <span className="orange-icon">
                                {key?.openerStatus}
                              </span>
                            </h6>
                          </div>
                          <div className="">
                            {key && (
                              <SmartButtonGroup
                                handleSmartLockButtonClick={
                                  handleOpenerButtonClick
                                }
                                newKey={key}
                                smartLockStatus={openerStatus}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    {/* <div className="orange-icon d-flex mt-3 gap-1 align-items-center">
                  <IoLockOpen />
                  <span>Lock is Close by: Andress Istvan,12.05 Pm </span>
                </div> */}
                  </CardBody>
                  <div className="card-footer border-top-dashed">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <span className="text-muted">
                          <i className="ri-time-line align-bottom">
                            {FormatedDate(key?.createdAt)}
                          </i>
                        </span>
                      </div>
                      <div className="flex-shrink-0">
                        <ul className="link-inline mb-0">
                          <li className="list-inline-item">
                            <Link
                              to={`/view-key/${key?._id}`}
                              className="Link"
                            >
                              <div>
                                <Button
                                  variant="outline"
                                  className="d-flex align-items-center justify-content-center white-button w-100 w-sm-50"
                                >
                                  <IoEyeOutline className="me-1" />
                                  <span>View Key</span>
                                </Button>
                              </div>
                            </Link>
                          </li>
                          {/* <li className="list-inline-item">
                            <Button
                              color="danger"
                              variant="outline"
                              onClick={() => handleDeleteKey(key?._id)}
                              className="w-100 d-flex align-items-center justify-content-center white-button hover-red"
                            >
                              <RiDeleteBin6Line className="me-2" />
                              Delete Key
                            </Button>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

            ))
          ) : (
            <div className="d-flex justify-content-center">
              <div>
                <h4 className="text-white p-2 bg-danger rounded shadow">
                  No Favourite Key found
                </h4>
              </div>
            </div>
          )}
        </Row>

        <Card>
          <CardHeader className="">
            <h6 className="card-title mb-0 flex-grow-1 fw-bold">
              Latest Requests
            </h6>
          </CardHeader>
        </Card>
        {loading ? (
          <div className="d-flex justify-content-center my-3">
            <Spinner
              animation="border"
              role="status"
              className="text-success"
              style={{ width: "2rem", height: "2rem" }}
            />
          </div>
        ) : (
          <Row>
            {getBookingApiDataRes && getBookingApiDataRes.length > 0 ? (
              getBookingApiDataRes.map((data) => (
                <Col xxl={6} sm={6} key={data?._id}>
                  <Card className="mb-3">
                    <CardBody>
                      <h5 className="d-flex justify-content-between align-items-center mb-4">
                        <div className="">{data.keyName}</div>
                        <span
                          className={`badge text-uppercase ${data.status === "rejected"
                            ? "bg-danger"
                            : data.status === "pending_approval"
                              ? "bg-warning"
                              : data.status === "cancelled"
                                ? "bg-danger"
                                : "bg-success"
                            }`}
                        >
                          {data.status}
                        </span>
                      </h5>
                      <div className="table-card">
                        <table className="table mb-3">
                          <tbody>
                            <tr>
                              <td className="fw-medium">Customer Name</td>
                              <td>
                                {data.userFirstName} {data.userLastName}
                              </td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Booking Date:</td>
                              <td>{FormatedDate(data.bookingCreatedTime)}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Contact Number:</td>
                              <td>{data.userContactNumber}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Customer Email:</td>
                              <td>{data.userEmail}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Booking Start Time:</td>
                              <td>{FormatedDate(data.bookingStartTime)}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Booking End Time:</td>
                              <td>{FormatedDate(data.bookingEndTime)}</td>
                            </tr>
                          </tbody>
                        </table>
                        {data.status === "pending_approval" && (
                          <div className="d-flex justify-content-end gap-2 mt-3 mb-3 me-2">
                            <Button
                              style={{
                                background: "#15294E",
                                color: "white",
                                borderColor: "gray",
                              }}
                              onClick={() => handleBooking(data?._id, "booked")}
                              className="blue-button"
                            >
                              Accept Booking
                            </Button>
                            <Button
                              onClick={() =>
                                handleBooking(data?._id, "rejected")
                              }
                              color="danger"
                              variant="outline"
                              className="d-flex align-items-center justify-content-center hover-red"
                            >
                              <RiDeleteBin6Line className="me-1" />
                              Reject Booking
                            </Button>
                          </div>
                        )}
                        {data.status === "booked" && (
                          <div className="d-flex justify-content-end gap-2 mt-3 me-2 mb-2">
                            <Button
                              onClick={() =>
                                handleBooking(data?._id, "cancelled")
                              }
                              color="danger"
                              variant="outline"
                              className="d-flex align-items-center justify-content-center hover-red"
                            >
                              <RiDeleteBin6Line className="me-1" />
                              Cancel
                            </Button>
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))
            ) : (
              <div className="d-flex justify-content-center">
                <div>
                  <h4 className="text-white p-2 bg-danger rounded shadow">
                    No Key Requests found
                  </h4>
                </div>
              </div>
            )}

            <Pagination
              data={getBookingApiDataRes}
              currentPage={page}
              setCurrentPage={setCurrentPage}
              perPageData={perPageData}
              handleNextKeysApi={handleNextKeysApi}
              handlePrevKeysApi={handlePrevKeysApi}
              customPageSize={6}
            />
          </Row>
        )}

      </div>
    </React.Fragment>
  );
};

export default DashboardEcommerce;
