import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import Flatpickr from "react-flatpickr";
import WeekdaysCheckbox from "./WeekdaysCheckbox";
import { FaPlusCircle } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import FilePondComp from "../components/FilePondComp";
import { FormatTime } from "../../../helpers/date_helper";
import SingleSelect from "../components/SingleSelect";
import { getAllKeyGroups } from "../../../slices/keyGroups/thunk";
import { AddKey, getOneKey, updateOneKey } from "../../../slices/keys/thunk";
import {
  resetAddKey,
  resetError,
  resetOneKey,
} from "../../../slices/keys/reducer";
import { NukiConnectedOrNot } from "../../../slices/nuki/thunk";
import { AllSmartLocks } from "../../../slices/nuki/thunk";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import WarningAlert from "../components/WarningAlert";
import { resetAllSmartLocks } from "../../../slices/nuki/reducer";
import KeyChainSelector from "../components/KeyChainSelector";

const EditKey = () => {
  document.title = "Swipe panel";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ID } = useParams();

  const [slots, setSlots] = useState([{ startTime: "", endTime: "" }]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedSmartlock, setSelectedSmartlock] = useState(null);
  const [selectedOpnerlock, setSelectedOpnerlock] = useState(null);
  const [shortSlots, setShortSlots] = useState([
    { startTime: "", endTime: "" },
  ]);
  const [files, setFiles] = useState([]);
  const [fileIds, setFileIds] = useState([]);

  // const keyGroupsRes = useSelector(
  //   (state) => state.KeyGroupsSlice.allKeyGroups.data
  // );
  const nukiConnectionRes = useSelector(
    (state) => state.NukiSlice.nukiConnection
  );
  const errorRes = useSelector((state) => state.KeySlice.error);
  const oneKeyRes = useSelector((state) => state.KeySlice.oneKey.data);
  const addKeyRes = useSelector((state) => state.KeySlice.addKey);
  const allSmartLocksRes = useSelector(
    (state) => state.NukiSlice.allSmartLocks
  );

  useEffect(() => {
    return () => {
      dispatch(resetError());
      dispatch(resetOneKey());
      setFileIds([]);
      setSelectedData(null);
    };
  }, []);

  useEffect(() => {
    if (allSmartLocksRes && allSmartLocksRes.success && allSmartLocksRes?.data?.smartlocks?.length === 0) {
      toast.error(allSmartLocksRes.message);
      dispatch(resetAllSmartLocks());
    } else {
      toast.success(allSmartLocksRes.message)
    }
  }, [allSmartLocksRes]);

  useEffect(() => {
    if (addKeyRes && addKeyRes?.success) {
      navigate("/all-keys");
    }
  }, [addKeyRes]);

  useEffect(() => {
    if (ID) {
      dispatch(getOneKey(ID));
    }
  }, [ID])

  useEffect(() => {
    let longTime = oneKeyRes?.key?.long_term_bookingTimes.map((key) => ({
      startTime: key.startTime,
      endTime: key.endTime,
    }));
    let shortTime = oneKeyRes?.key?.short_term_bookingTimes.map((key) => ({
      startTime: key.startTime,
      endTime: key.endTime,
    }));
    if (oneKeyRes) {
      let smartParams = {
        _id: oneKeyRes?.key?.smartlockId,
        name: allSmartLocksRes?.data?.smartlocks?.find(smartlock =>
          String(smartlock.smartlockId) === String(oneKeyRes?.key?.smartlockId)
        )?.name
      };
      let openerParams = {
        _id: oneKeyRes?.key?.openers,
        name: allSmartLocksRes?.data?.openers?.find(openers =>
          String(openers.smartlockId) === String(oneKeyRes?.key?.openerId)
        )?.name
      };
      setSelectedData(oneKeyRes?.key?.keyGroupId);
      setSelectedSmartlock(smartParams);
      setSelectedOpnerlock(openerParams);
      setSlots(longTime);
      setShortSlots(shortTime);
    }
  }, [oneKeyRes, allSmartLocksRes]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: oneKeyRes?.key.name || "",
      phoneNumber: oneKeyRes?.key.phoneNumber || "",
      email: oneKeyRes?.key.email || "",
      propertyLink: oneKeyRes?.key.propertyLink || "",
      address: oneKeyRes?.key.address || "",
      keyPasscodeHouseDoor: oneKeyRes?.key?.keyPasscodeHouseDoor || "",
      keyPasscodeFlatDoor: oneKeyRes?.key?.keyPasscodeFlatDoor || "",
      keyGroupId: oneKeyRes?.key?.keyGroupId || "",
      smart_lock_enabled: oneKeyRes?.key.smart_lock_enabled || false,
      smartlockId: oneKeyRes?.key.smartlockId || "null",
      openerId: oneKeyRes?.key.openerId || "null",
      ringBell: oneKeyRes?.key.ringBell || false,
      ringName: oneKeyRes?.key.ringName || "null",
      long_term_booking_enabled:
        oneKeyRes?.key.long_term_booking_enabled || false,
      long_term_bookingTimes: oneKeyRes?.key.long_term_bookingTimes || [],
      long_term_weekdays: oneKeyRes?.key.long_term_weekdays || [],
      long_term_offDays: oneKeyRes?.key.long_term_offDays || [],
      short_term_booking_enabled:
        oneKeyRes?.key.short_term_booking_enabled || false,
      short_term_bookingTimes: oneKeyRes?.key.short_term_bookingTimes || [],
      short_term_weekdays: oneKeyRes?.key.short_term_weekdays || [],
      short_term_offDays: oneKeyRes?.key.short_term_offDays || [],
      short_term_SlotLength: oneKeyRes?.key.short_term_SlotLength || null,
      long_term_MaxDays: oneKeyRes?.key.long_term_MaxDays || null,
      mediaIds: oneKeyRes?.key.mediaIds || [],
      remarks: oneKeyRes?.key.remarks || "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Key Name"),
      email: Yup.string().required("Please Enter Email Address"),
      phoneNumber: Yup.string()
        .matches(/^\d+$/, "Phone number must contain only numbers")
        .required("Please Enter Phone Number"),

    }),

    onSubmit: (values) => {
      const newKey = {
        name: values.name,
        phoneNumber: values.phoneNumber,
        email: values.email,
        propertyLink: values.propertyLink,
        address: values.address,
        keyPasscodeHouseDoor: values.keyPasscodeHouseDoor,
        keyPasscodeFlatDoor: values.keyPasscodeFlatDoor,
        smart_lock_enabled: values.smart_lock_enabled,
        smartlockId: selectedSmartlock?.value,
        openerId: selectedOpnerlock?.value,
        ringBell: values.ringBell,
        ringName: values.ringName,
        long_term_booking_enabled: values.long_term_booking_enabled,
        long_term_bookingTimes: slots.map((slot) => ({
          startTime: slot?.startTime,
          endTime: slot?.endTime,
        })),
        long_term_weekdays: values.long_term_weekdays,
        long_term_offDays: values.long_term_offDays,
        short_term_booking_enabled: values.short_term_booking_enabled,
        short_term_bookingTimes: shortSlots,
        short_term_weekdays: values.short_term_weekdays,
        short_term_offDays: values.short_term_offDays || [],
        short_term_SlotLength: values.short_term_SlotLength,
        long_term_MaxDays: values.long_term_MaxDays,
        mediaIds: [...(fileIds || []), ...(oneKeyRes?.key.mediaIds || [])],
        remarks: values.remarks,
        ...(selectedData?.value && { keyGroupId: selectedData.value }),
      };
      if (ID) {
        dispatch(updateOneKey({ values: newKey, ID }));
        navigate(`/view-key/${ID}`);
      }
    },
  });

  useEffect(() => {
    dispatch(resetOneKey());
    setShortSlots([{ startTime: "", endTime: "" }]);
    setSlots([{ startTime: "", endTime: "" }]);
    dispatch(NukiConnectedOrNot());
  }, [dispatch]);

  useEffect(() => {
    if (validation.values.smart_lock_enabled) {
      dispatch(AllSmartLocks());
    }
  }, [validation.values.smart_lock_enabled])

  const handleSmartLockChange = () => {
    validation.setFieldValue(
      "smart_lock_enabled",
      !validation.values.smart_lock_enabled
    );
  };

  const handleShortCheckboxChange = (e) => {
    validation.setFieldValue(
      "short_term_booking_enabled",
      !validation.values.short_term_booking_enabled
    );
  };

  useEffect(() => {
    if (!validation.values.short_term_booking_enabled) {
      validation.setFieldValue("short_term_SlotLength", "");
      validation.setFieldValue("short_term_weekdays", []);
      validation.setFieldValue("short_term_offDays", "");
      setShortSlots([{ startTime: "", endTime: "" }]);
    }
    if (!validation.values.long_term_booking_enabled) {
      validation.setFieldValue("long_term_SlotLength", "");
      validation.setFieldValue("long_term_weekdays", []);
      validation.setFieldValue("long_term_offDays", []);
      validation.setFieldValue("long_term_MaxDays", null);
      setSlots([{ startTime: "", endTime: "" }]);
    }
  }, [
    validation.values.short_term_booking_enabled,
    validation.values.long_term_booking_enabled,
  ]);

  const handleLongCheckboxChange = (e) => {
    validation.setFieldValue(
      "long_term_booking_enabled",
      !validation.values.long_term_booking_enabled
    );
  };

  const handleRingBellChange = (event) => {
    validation.setFieldValue("ringBell", event.target.value === "true");
  };

  const handleSlotLengthChange = (event) => {
    const selectedSlotLength = parseInt(event.target.value, 10);
    validation.setFieldValue("short_term_SlotLength", selectedSlotLength);
  };

  const handleLongDaysChange = (event) => {
    const selectedLength = parseInt(event.target.value, 10);
    validation.setFieldValue("long_term_MaxDays", selectedLength);
  };

  const selectedWeekdays = (selectedDays) => {
    validation.setFieldValue("short_term_weekdays", selectedDays);
  };

  const selectedLongWeekdays = (selectedDays) => {
    validation.setFieldValue("long_term_weekdays", selectedDays);
  };

  const handleStartShortTimeChange = (time, index) => {
    let timeData = FormatTime(time[0]);
    const newSlots = [...shortSlots];
    newSlots[index].startTime = timeData;
    setShortSlots(newSlots);
  };

  const handleEndShortTimeChange = (time, index) => {
    let timeData = FormatTime(time[0]);
    const newSlots = [...shortSlots];
    newSlots[index].endTime = timeData;
    setShortSlots(newSlots);
  };

  const handleStartLongTimeChange = (time, index) => {
    let timeData = FormatTime(time[0]);
    const newSlots = [...slots];
    newSlots[index].startTime = timeData;
    setSlots(newSlots);
  };

  const handleEndLongTimeChange = (time, index) => {
    let timeData = FormatTime(time[0]);
    const newSlots = [...slots];
    newSlots[index].endTime = timeData;
    setSlots(newSlots);
  };

  const handleDateChange = (selectedDates) => {
    const formattedDates = selectedDates.map(
      (date) =>
        new Date(date.getTime() - date.getTimezoneOffset() * 60000)
          .toISOString()
          .split("T")[0]
    );
    validation.setFieldValue("short_term_offDays", formattedDates);
  };

  const handleLongDateChange = (selectedDates) => {
    const formattedDates = selectedDates.map(
      (date) =>
        new Date(date.getTime() - date.getTimezoneOffset() * 60000)
          .toISOString()
          .split("T")[0]
    );
    validation.setFieldValue("long_term_offDays", formattedDates);
  };

  const handleSelectedKey = (data) => {
    if (data) {
      setSelectedData(data);
    } else {
      setSelectedData(null);
    }
  };
  const handleSelecteSmartId = (data) => {
    setSelectedSmartlock(data);
  };
  const handleSelecteOpenerId = (data) => {
    setSelectedOpnerlock(data);
  };

  const addMoreSlots = () => {
    setSlots([...slots, { startTime: "", endTime: "" }]);
  };
  const addMoreShortSlots = () => {
    setShortSlots([...shortSlots, { startTime: "", endTime: "" }]);
  };

  const deleteShortTime = (indexToDelete) => {
    setShortSlots(shortSlots.filter((_, index) => index !== indexToDelete));
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <div className="page-content">
      <ToastContainer limit={1} />
      <Container fluid>
        <BreadCrumb title={ID ? `Edit Key` : `Add Key`} pageTitle="Ecommerce" />
        {errorRes && errorRes ? <WarningAlert msg={errorRes} /> : null}
        <Row>
          <Col lg={12}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <div>
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Basic Info</h5>
                  </CardHeader>
                  <CardBody>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="key-name-input">
                        Key Name
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="key-name-input"
                        placeholder="Enter Key Name"
                        name="name"
                        value={validation.values.name || ""}
                        onBlur={validation.handleBlur}
                        onKeyPress={handleKeyPress}
                        onChange={validation.handleChange}
                        invalid={
                          validation.errors.name && validation.touched.name
                            ? true
                            : false
                        }
                      />
                      {validation.errors.name && validation.touched.name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.name}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="key-name-input">
                        Key Address
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="key-name-input"
                        placeholder="Enter Key Name"
                        name="address"
                        value={validation.values.address}
                        onBlur={validation.handleBlur}
                        onKeyPress={handleKeyPress}
                        onChange={validation.handleChange}
                        invalid={
                          validation.errors.address &&
                          validation.touched.address
                        }
                      />
                      {validation.errors.address &&
                        validation.touched.address ? (
                        <FormFeedback type="invalid">
                          {validation.errors.address}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="key-property-input"
                      >
                        Key Property Link
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="key-property-input"
                        placeholder="Enter Key Property Link"
                        name="propertyLink"
                        value={validation.values.propertyLink || ""}
                        onBlur={validation.handleBlur}
                        onKeyPress={handleKeyPress}
                        onChange={validation.handleChange}
                        invalid={
                          validation.errors.propertyLink &&
                            validation.touched.propertyLink
                            ? true
                            : false
                        }
                      />
                      {validation.errors.propertyLink &&
                        validation.touched.propertyLink ? (
                        <FormFeedback type="invalid">
                          {validation.errors.propertyLink}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </CardBody>
                </Card>
              </div>

              <div>
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Contact Info</h5>
                  </CardHeader>
                  <CardBody>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="key-number-input">
                        Phone Number In Charge
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="key-number-input"
                        placeholder="Enter Phone Number"
                        name="phoneNumber"
                        value={validation.values.phoneNumber || ""}
                        onBlur={validation.handleBlur}
                        onKeyPress={handleKeyPress}
                        onChange={validation.handleChange}
                        invalid={
                          validation.errors.phoneNumber &&
                            validation.touched.phoneNumber
                            ? true
                            : false
                        }
                      />
                      {validation.errors.phoneNumber &&
                        validation.touched.phoneNumber ? (
                        <FormFeedback type="invalid">
                          {validation.errors.phoneNumber}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="key-email-input">
                        Email In Charge
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="key-email-input"
                        placeholder="Enter Email In Charge"
                        name="email"
                        value={validation.values.email || ""}
                        onBlur={validation.handleBlur}
                        onKeyPress={handleKeyPress}
                        onChange={validation.handleChange}
                        invalid={
                          validation.errors.email && validation.touched.email
                            ? true
                            : false
                        }
                      />
                      {validation.errors.email && validation.touched.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </CardBody>
                </Card>
              </div>

              <div>
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Access Info</h5>
                  </CardHeader>
                  <CardBody>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="key-house-input">
                        House Door(Code)
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="key-house-input"
                        placeholder="Enter House Door(Code)"
                        name="keyPasscodeHouseDoor"
                        value={validation.values.keyPasscodeHouseDoor || ""}
                        onBlur={validation.handleBlur}
                        onKeyPress={handleKeyPress}
                        onChange={validation.handleChange}
                        invalid={
                          validation.errors.keyPasscodeHouseDoor &&
                            validation.touched.keyPasscodeHouseDoor
                            ? true
                            : false
                        }
                      />
                      {validation.errors.keyPasscodeHouseDoor &&
                        validation.touched.keyPasscodeHouseDoor ? (
                        <FormFeedback type="invalid">
                          {validation.errors.keyPasscodeHouseDoor}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="key-flat-input">
                        Flat Door(Code)
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="key-flat-input"
                        placeholder="Enter Flat Door(Code)"
                        name="keyPasscodeFlatDoor"
                        value={validation.values.keyPasscodeFlatDoor || ""}
                        onBlur={validation.handleBlur}
                        onKeyPress={handleKeyPress}
                        onChange={validation.handleChange}
                        invalid={
                          validation.errors.keyPasscodeFlatDoor &&
                            validation.touched.keyPasscodeFlatDoor
                            ? true
                            : false
                        }
                      />
                      {validation.errors.keyPasscodeFlatDoor &&
                        validation.touched.keyPasscodeFlatDoor ? (
                        <FormFeedback type="invalid">
                          {validation.errors.keyPasscodeFlatDoor}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="">
                      <Label className="form-label" htmlFor="key-remarks-input">
                        Remarks
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="key-remarks-input"
                        placeholder="Enter remarks"
                        name="remarks"
                        value={validation.values.remarks || ""}
                        onBlur={validation.handleBlur}
                        onKeyPress={handleKeyPress}
                        onChange={validation.handleChange}
                        invalid={
                          validation.errors.remarks &&
                            validation.touched.remarks
                            ? true
                            : false
                        }
                      />
                      {validation.errors.remarks &&
                        validation.touched.remarks ? (
                        <FormFeedback type="invalid">
                          {validation.errors.remarks}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mt-3">
                      <Label className="form-label" htmlFor="key-chain">
                        Select Keychain
                      </Label>
                      <KeyChainSelector
                        handleSelectedFnc={handleSelectedKey}
                        selectedData={selectedData}
                        // data={keyGroupsRes}
                        placeholder={"Select Keychain"}
                      />
                    </div>
                  </CardBody>
                </Card>
              </div>
              {ID && (
                <div>
                  <FilePondComp
                    files={files}
                    setFiles={setFiles}
                    setFileIds={setFileIds}
                    name="files"
                  />
                  {oneKeyRes &&
                    oneKeyRes?.key?.mediaIds?.map((img) => (
                      <figure className="figure mb-0" key={img?._id}>
                        <img
                          src={img?.url}
                          className="figure-img img-fluid rounded me-2"
                          style={{ maxWidth: "200px", width: "100%" }}
                          alt="..."
                        />
                      </figure>
                    ))}
                </div>
              )}
              <div className="mt-2">
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Smart Lock Info</h5>
                  </CardHeader>
                  <CardBody>
                    <div
                      className="form-check form-switch form-switch-md mb-3"
                      dir="ltr"
                    >
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="smart_lock_enabled"
                        name="smart_lock_enabled"
                        disabled={!nukiConnectionRes?.connected}
                        checked={validation.values.smart_lock_enabled}
                        onKeyPress={handleKeyPress}
                        onChange={handleSmartLockChange}
                      />
                      <Label
                        className="form-check-label"
                        for="smart_lock_enabled"
                      >
                        Configure Smartlock
                      </Label>
                    </div>
                    {validation.values.smart_lock_enabled && (
                      <div>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Smartlock Id
                          </Label>
                          <SingleSelect
                            handleSelectedFnc={handleSelecteSmartId}
                            selectedData={selectedSmartlock}
                            data={allSmartLocksRes?.data?.smartlocks}
                            placeholder={"Select Smart lock"}
                          />
                          {/* {validation.errors.smartlockId &&
                          validation.touched.smartlockId ? (
                            <FormFeedback type="invalid">
                              {validation.errors.smartlockId}
                            </FormFeedback>
                          ) : null} */}
                        </div>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="key-open-id-input"
                          >
                            Open Id
                          </Label>
                          <SingleSelect
                            handleSelectedFnc={handleSelecteOpenerId}
                            selectedData={selectedOpnerlock}
                            data={allSmartLocksRes?.data?.openers}
                            placeholder={"Select Opener"}
                          />
                          {/* {validation.errors.openerId &&
                          validation.touched.openerId ? (
                            <FormFeedback type="invalid">
                              {validation.errors.openerId}
                            </FormFeedback>
                          ) : null} */}
                        </div>
                        <div className="mt-3 gap-2 mb-4">
                          <h6>Intercorm</h6>
                          <div className="form-check d-flex form-radio-primary gap-5 ">
                            <div className="">
                              <Input
                                className="form-check-input"
                                type="radio"
                                name="ringBell"
                                id="ringBellTrue"
                                value="true"
                                checked={validation.values.ringBell === true}
                                onKeyPress={handleKeyPress}
                                onChange={handleRingBellChange}
                              />
                              <Label
                                className="form-check-label"
                                for="flexRadioDefault1"
                              >
                                You have to ring too open
                              </Label>
                            </div>

                            <div className="">
                              <Input
                                className="form-check-input"
                                type="radio"
                                name="ringBell"
                                id="ringBellFalse"
                                value="false"
                                checked={validation.values.ringBell === false}
                                onKeyPress={handleKeyPress}
                                onChange={handleRingBellChange}
                              />
                              <Label
                                className="form-check-label"
                                for="flexRadioDefault2"
                              >
                                Open without ringing
                              </Label>
                            </div>
                          </div>
                          {validation.values.ringBell && (
                            <div className="row">
                              <div className="custom-input-container mt-3 col-12">
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="key-ring-input"
                                  >
                                    Ring Name
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="key-ring-input"
                                    placeholder="Enter Ring Name"
                                    name="ringName"
                                    value={validation.values.ringName || ""}
                                    onBlur={validation.handleBlur}
                                    onKeyPress={handleKeyPress}
                                    onChange={validation.handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </div>

              <div>
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Short Booking Info</h5>
                  </CardHeader>
                  <CardBody>
                    <div className="mb-3">
                      <div
                        className="form-check form-switch form-switch-md"
                        dir="ltr"
                      >
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          id="short_term_booking_enabled"
                          name="short_term_booking_enabled"
                          checked={validation.values.short_term_booking_enabled}
                          onKeyPress={handleKeyPress}
                          onChange={handleShortCheckboxChange}
                        />
                        <Label className="form-check-label" for="shortChecked">
                          Book Short Term
                        </Label>
                      </div>
                      {validation.values.short_term_booking_enabled && (
                        <>
                          <Row>
                            <Col lg={5}>
                              <div className="d-flex gap-2">
                                <h5 className="mt-3">Timings:</h5>
                                <span
                                  onClick={addMoreShortSlots}
                                  style={{ cursor: "pointer" }}
                                  className="hover-white mt-3"
                                >
                                  <FaPlusCircle size="20" />
                                </span>
                              </div>
                              {shortSlots.map((slot, index) => (
                                <div
                                  key={index}
                                  className="d-flex justify-content-between flatpicker mt-3"
                                >
                                  <div>
                                    <Label className="form-label mb-0">
                                      Start Time:
                                    </Label>
                                    <Flatpickr
                                      className="form-control"
                                      placeholder="00:00"
                                      options={{
                                        enableTime: true,
                                        noCalendar: true,
                                        dateFormat: "H:i",
                                      }}
                                      value={slot.startTime}
                                      onKeyPress={handleKeyPress}
                                      onChange={(e) =>
                                        handleStartShortTimeChange(e, index)
                                      }
                                    />
                                  </div>
                                  <div>
                                    <Label className="form-label mt-4 me-2 ms-2 ">
                                      To
                                    </Label>
                                  </div>
                                  <div className="me-2">
                                    <Label className="form-label mb-0">
                                      End Time:
                                    </Label>
                                    <Flatpickr
                                      className="form-control"
                                      placeholder="00:00"
                                      options={{
                                        enableTime: true,
                                        noCalendar: true,
                                        dateFormat: "H:i",
                                      }}
                                      value={slot.endTime}
                                      onKeyPress={handleKeyPress}
                                      onChange={(e) =>
                                        handleEndShortTimeChange(e, index)
                                      }
                                    />
                                  </div>
                                  <div
                                    className="mt-4 me-2"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => deleteShortTime(index)}
                                  >
                                    <RiDeleteBin6Line fill="red" />
                                  </div>
                                </div>
                              ))}
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6}>
                              <div>
                                <Label className="mt-3 form-label">
                                  Slots Minutes:
                                </Label>
                                <td>
                                  <select
                                    className="form-control"
                                    id="t-status"
                                    name="short_term_SlotLength"
                                    aria-label="Default select example"
                                    value={
                                      validation.values.short_term_SlotLength
                                    }
                                    onKeyPress={handleKeyPress}
                                    onChange={handleSlotLengthChange}
                                  >
                                    <option value="null">00</option>
                                    <option value="15">15</option>
                                    <option value="30">30</option>
                                    <option value="45">45</option>
                                    <option value="60">60</option>
                                    <option value="75">75</option>
                                    <option value="90">90</option>
                                  </select>
                                </td>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6}>
                              <div className="mt-3">
                                <Label className="form-label mb-0">
                                  Choose week days:
                                </Label>
                                <div className="mt-1">
                                  <WeekdaysCheckbox
                                    name="shortSelected"
                                    selectedWeekdays={selectedWeekdays}
                                    selectedData={
                                      oneKeyRes?.key?.short_term_weekdays
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6}>
                              <div className="mt-2">
                                <Label className="form-label mb-0">
                                  Selecting Days Offs:
                                </Label>
                                <Flatpickr
                                  className="form-control"
                                  placeholder="You can select multiple days"
                                  options={{
                                    mode: "multiple",
                                    dateFormat: "Y-m-d",
                                    defaultDate:
                                      oneKeyRes?.key?.short_term_offDays,
                                  }}
                                  onKeyPress={handleKeyPress}
                                  onChange={handleDateChange}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </div>

              <div>
                <h5 className=""></h5>
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Long Booking Info</h5>
                  </CardHeader>
                  <CardBody>
                    <div className="mb-3">
                      <div
                        className="form-check form-switch form-switch-md"
                        dir="ltr"
                      >
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          id="long_term_booking_enabled"
                          name="long_term_booking_enabled"
                          checked={validation.values.long_term_booking_enabled}
                          onKeyPress={handleKeyPress}
                          onChange={handleLongCheckboxChange}
                        />
                        <Label className="form-check-label" for="LongChecked">
                          Book Long Term
                        </Label>
                      </div>
                      {validation.values.long_term_booking_enabled && (
                        <>
                          <Row>
                            <Col lg={5}>
                              <div className="d-flex gap-2">
                                <h5 className="mt-3">Timings:</h5>
                                <span
                                  onClick={addMoreSlots}
                                  style={{ cursor: "pointer" }}
                                  className="hover-white mt-3"
                                ></span>
                              </div>
                              {slots.map((slot, index) => (
                                <div
                                  key={index}
                                  className="d-flex justify-content-between flatpicker mt-3"
                                >
                                  <div>
                                    <Label className="form-label mb-0">
                                      Start Time:
                                    </Label>
                                    <Flatpickr
                                      className="form-control"
                                      placeholder="00:00"
                                      options={{
                                        enableTime: true,
                                        noCalendar: true,
                                        dateFormat: "H:i",
                                      }}
                                      value={slot.startTime}
                                      onKeyPress={handleKeyPress}
                                      onChange={(e) =>
                                        handleStartLongTimeChange(e, index)
                                      }
                                    />
                                  </div>
                                  <div>
                                    <Label className="form-label mt-4">
                                      To
                                    </Label>
                                  </div>
                                  <div>
                                    <Label className="form-label mb-0">
                                      End Time:
                                    </Label>
                                    <Flatpickr
                                      className="form-control"
                                      placeholder="00:00"
                                      options={{
                                        enableTime: true,
                                        noCalendar: true,
                                        dateFormat: "H:i",
                                      }}
                                      value={slot.endTime}
                                      onKeyPress={handleKeyPress}
                                      onChange={(e) =>
                                        handleEndLongTimeChange(e, index)
                                      }
                                    />
                                  </div>
                                </div>
                              ))}
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6}>
                              <div>
                                <Label className="mt-3 form-label">
                                  Maximum Days:
                                </Label>
                                <td>
                                  <select
                                    className="form-control"
                                    id="t-status"
                                    name="long_term_MaxDays"
                                    aria-label="Default select example"
                                    value={validation.values.long_term_MaxDays}
                                    onKeyPress={handleKeyPress}
                                    onChange={handleLongDaysChange}
                                  >
                                    <option value="null">0</option>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                    <option value="30">30</option>
                                  </select>
                                </td>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6}>
                              <div className="mt-3">
                                <Label className="form-label mb-0">
                                  Choose week days:
                                </Label>
                                <div className="mt-1">
                                  <WeekdaysCheckbox
                                    name="longSelected"
                                    selectedWeekdays={selectedLongWeekdays}
                                    selectedData={
                                      oneKeyRes?.key?.long_term_weekdays
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6}>
                              <div className="mt-2">
                                <Label className="form-label mb-0">
                                  Selecting Days Offs:
                                </Label>
                                <Flatpickr
                                  className="form-control"
                                  placeholder="You can select multiple days"
                                  options={{
                                    mode: "multiple",
                                    dateFormat: "Y-m-d",
                                    defaultDate:
                                      oneKeyRes?.key?.long_term_offDays,
                                  }}
                                  onKeyPress={handleKeyPress}
                                  onChange={handleLongDateChange}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </div>

              <div className="text-end mb-3">
                <button
                  type="submit"
                  className="blue-button py-1 px-4 border-none"
                >
                  Submit
                </button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EditKey;
